import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/create`, form),
  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/update`, form),
  delete: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/delete`, form),
  getCategories: (options) => api(options).post(`${PORTAL_SERVICE_V1}/template/getCategories`),
  getOwners: (options) => api(options).post(`${PORTAL_SERVICE_V1}/template/getOwners`),
  getExternalTemplates: (options) => api(options).get(`${PORTAL_SERVICE_V1}/template/getExternalTemplates`),
  getExternalTemplatesByType: (type, options) => api(options).get(`${PORTAL_SERVICE_V1}/template/getExternalTemplates/${type}`),
  getExternalTemplateTypes: (options) => api(options).get(`${PORTAL_SERVICE_V1}/template/getExternalTemplateTypes`),
  getContentTypes: (options) => api(options).post(`${PORTAL_SERVICE_V1}/template/getContentTypes`),
  getTemplateTypes: (options) => api(options).post(`${PORTAL_SERVICE_V1}/template/getTemplateTypes`),
  getPredefinedTemplateNames: (category, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/getPredefinedTemplateNames/${category}`),
  get: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/get`, filter),
  getMostRecentParameters: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/preview/mostRecentParameters`, filter),

  previewRender: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/template/preview/render`, form)
}
